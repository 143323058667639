import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { SectionPage, Container, BreadCrumb, SectionPageTitle } from "../components/Section"
import CaseStudiesList from "../components/CaseStudiesList"

class CaseStudiesPage extends React.Component {
  
  render() {
    const caseStudyData = this.props.data.allContentfulCaseStudy.edges
    return (
      <Layout location={this.props.location}>
        <SEO title="Case Studies" description="Case Studies" />
        <SectionPage
          ept="160px"
          epb="60px"
          xpt="140px"
          xpb="40px"
          pt="100px"
          pb="30px"
          bg="#fff"
        >
           <Container>
            <BreadCrumb className="static"><Link to="/">Home</Link> / <span>Case Studies</span></BreadCrumb>
            <SectionPageTitle textAlign="center">Case Studies</SectionPageTitle>
            <CaseStudiesList data={caseStudyData} />
          </Container>
        </SectionPage>
        <CallToAction />
      </Layout>
    )
  }
}

export default CaseStudiesPage

export const pageQuery = graphql`
  query CaseStudiesPageTemplateQuery {
    allContentfulCaseStudy(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          slug
          thumbnail {
            fluid(maxWidth: 980) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          content {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`
